<template>
  <Title
    :title="title"
  />
</template>
<script>
import { mapState } from 'pinia';
import { useProcessesStore } from '@/stores/index.js';
import Title from '@/components/title.vue';
import { formatDate } from '@/utils/index.js';

export default {
  components: { Title },

  computed: {
    ...mapState(useProcessesStore, ['job']),

    title() {
      if (!this.job) return '';
      return `Run ${formatDate({ date: this.job?.start, format: 'MMM DD, hh:mm A' })}`;
    },
  },
};
</script>
